import Link from "next/link";
import { GridContainer } from "../../../layouts/grid_container";

export const FooterLinks = () => {
  return (
    <>
      <GridContainer className="grid desktop:hidden text-xs desktop:text-inherit">
        <div className="col-start-1 col-span-full flex flex-row items-center justify-evenly">
          <Link href="https://www.facebook.com/PINKO.official">
            <a target="_blank">Facebook</a>
          </Link>
          <Link href="https://www.instagram.com/pinkoofficial/">
            <a target="_blank">Instagram</a>
          </Link>
          <Link href="https://www.youtube.com/user/pinko">
            <a target="_blank">Youtube</a>
          </Link>
        </div>
        <div className="col-start-1 col-span-full flex flex-row items-center justify-evenly mt-3">
          <Link href="https://www.pinko.com">
            <a target="_blank">Pinko website</a>
          </Link>
          <Link href="https://www.pinko.com/customercare/privacy-policy.html">
            <a target="_blank">Privacy Policy</a>
          </Link>
          <Link href="https://www.iubenda.com/privacy-policy/70438218/cookie-policy">
            <a target="_blank">Cookie</a>
          </Link>
        </div>
      </GridContainer>
      <GridContainer className="hidden desktop:grid px-4">
        <div className="col-start-1 col-span-2">
          <Link href="https://www.pinko.com">
            <a target="_blank">Pinko website</a>
          </Link>
        </div>
        <div className="col-start-5 col-span-4">
          <div className="flex flex-row items-center justify-evenly">
            <Link href="https://www.facebook.com/PINKO.official">
              <a target="_blank">Facebook</a>
            </Link>
            <Link href="https://www.instagram.com/pinkoofficial/">
              <a target="_blank">Instagram</a>
            </Link>
            <Link href="https://www.youtube.com/user/pinko">
              <a target="_blank">Youtube</a>
            </Link>
          </div>
        </div>
        <div className="col-start-11 col-span-2">
          <div className="flex flex-row items-center justify-evenly">
            <Link href="https://www.pinko.com/customercare/privacy-policy.html">
              <a target="_blank">Privacy Policy</a>
            </Link>
            <Link href="https://www.iubenda.com/privacy-policy/70438218/cookie-policy">
              <a target="_blank">Cookie</a>
            </Link>
          </div>
        </div>
      </GridContainer>
    </>
  );
};
