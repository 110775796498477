export const LayoutOverlay = () => (
  <section
    style={{ gridAutoRows: "minmax(100%, auto)" }}
    className="pointer-events-none layout fixed bg-transparent top-0 left-0 right-0 bottom-0 p-0"
  >
    <p className="bg-black opacity-10"></p>
    <p className="bg-black opacity-10 "></p>
    <p className="bg-black opacity-10 "></p>
    <p className="bg-black opacity-10 "></p>
    <p className="bg-black opacity-10 "></p>
    <p className="bg-black opacity-10 "></p>
    <p className="bg-black opacity-10 hidden tablet:block"></p>
    <p className="bg-black opacity-10 hidden tablet:block"></p>
    <p className="bg-black opacity-10 hidden tablet:block"></p>
    <p className="bg-black opacity-10 hidden tablet:block"></p>
    <p className="bg-black opacity-10 hidden tablet:block"></p>
    <p className="bg-black opacity-10 hidden tablet:block"></p>
  </section>
);
