const messages = {
  "dragCarousel.cta.drag.label": "Drag",
  "scrollDownIndicator.label": "Scroll down - scroll down - scroll down -",
  "button.mintNow.label": "Mint now",
  "info.bagsCount": "10 Borse",
  "info.date": "16 Giugno 2022",
  "info.auctionDuration": "24h Asta",
  "info.price": "0.89 ETH",
  "info.hour": "11 AM PST / 2 PM EST / 6 PM GMT / 7 PM CET",
  "project.description.text": `Love Bag is a PINKO icon. 
    But a bag is also a container at its essence. <em>Meta Love Bags</em> expand the usage of this kind of accessory bringing it into the digital world.
    So the content opens to a “metaphysical” dimension. Different objects and shapes repeat and distribute, recreating the iconic shape of Love Bag, metaphorically held together and contained by the two love birds.
    The elements derive from pop culture, even though it’s a transgressive choice of pop icons putting the overacting spirit of PINKO inside the design.`,
  "title.collection.text": "Collection",
  "title.roadmap.text": "Roadmap",
  "roadmap.description":
    "This is our roadmap so far, but it’s just the beginning: we want to build our project together with our community, so if you have more ideas we’ll listen to you!",
  "faq.title": "Faq",
  "faq.description":
    "La cosa migliore di far parte del gruppo <em>PINKO Love Meta Love Bags</em> è che non ti sentirai mai solo e c'è sempre qualcuno pronto ad aiutarti!",
  "menu.project.label": "Project",
  "menu.collection.label": "NFT Collection",
  "menu.roadmap.label": "Roadmap",
  "menu.benefits.label": "Benefits",
  "menu.faq.label": "FAQ",
  "modal.mintingComplete.title": "Minting completato",
  "modal.mintingComplete.message":
    "Il minting è stato completato con successo. Verifica nel tuo wallet la presenza dell'NFT appena acquistato.",
  "modal.mintingComplete.buttonLabel": "Ok, capito!",
  "roadmap.step1.day": "June 2022",
  "roadmap.step1.title": "Collection Unveiling @Viva Tecnology 2022 - Paris",
  "roadmap.step1.description":
    "During the Viva Technology 2022, We unveiled the first 3 NFTs of the collection: PINKO moves officially its first steps into the metaverse!",
  "roadmap.step2.day": "from 7 to 20 November 2022",
  "roadmap.step2.title": "PINKO Meta Love Bags Collection first drop",
  "roadmap.step2.description": "Milan and New York boutiques are hosting the Meta Love Bags collection release events.",
  "roadmap.step3.day": "2023",
  "roadmap.step3.title": "Community",
  "roadmap.step3.description":
    "The purchase of NFT PINKO will allow PINKO customers to enter a dedicated community, through which you can access exclusive experiences / events, organized by PINKO.",
  "roadmap.step4.day": "2023",
  "roadmap.step4.title": "Capsule and exclusive experience",
  "roadmap.step4.description": `
  - PINKO Capsule Collection accessible only through the NFTs
  - Exclusive online and offline events for the Community
  - Private sales to the NFTs first collection owners(the OGs)
  `,
  "roadmap.step5.day": "2023",
  "roadmap.step5.title": "PINKO Metaverse",
  "roadmap.step5.description":
    "The NFT will give you access to the PINKO metaverse experience, an exclusive virtual world. You will be able to customize your avatar, and make them wear your NFTs, enjoying multiplayer experiences with other members of the community.",
  "faq.1.question": "How can I purchase PINKO’S NFTs?",
  "faq.1.answer":
    "To purchase an NFT you’ll need a Crypto Wallet (Metamask, Coinbase Wallet, Trust Wallet etc) with a balance of at least 0.4 ETH .",
  "faq.2.question": "What are the requirements to buy a PINKO Meta Love Bags NFT?",
  "faq.2.answer": `There are four requirements to buy a PINKO Meta Love Bags NFT:
    - You need to own a crypto wallet; 
    - You need 0.3 ETH on your crypto wallet for minting;
    - You need 0.1 ETH on your crypto wallet for gas fees;
    `,
  "faq.3.question": "How Many PINKO Meta Love Bags are available to purchase?",
  "faq.3.answer": "A total of 15 Bags will be available for purchase for this first drop.",
  "faq.4.question":
    "Is this first drop the only chance to get a PINKO Meta Love Bag or participate in the Pinko Metaverse?",
  "faq.4.answer":
    "There will be multiple PINKO NFT drops. These are the first 15 Meta Love Bags. The next drop has yet to be revealed.",
  "faq.5.question": "How do I view my Meta Love Bag?",
  "faq.5.answer":
    'Visit the "My NFTs" section after purchasing a Meta Love Bag on metalovebags.pinko.com, or import your PINKO NFT to your crypto wallet.',
  "faq.6.question": "What do you get with a PINKO Meta Love Bag?",
  "faq.6.answer": `By purchasing one of the first 15 Meta Love Bags you get: 
    - Exclusive access to the new Pinko community which will be launched in 2023;
    - Participation through gated polls sessions, to the co-design of Pinko Community prizes and rewards;
    - Access to exclusive PINKO events;
    - Early access to future PINKO NFT drops;
    - Access to special deals and private sales; 
    - 10% Special discount valid for 12 months after the purchase;
    `,
  "faq.7.question": "Where will secondary sales be for PINKO Meta Love Bags Collection?",
  "faq.7.answer": "You will be able to purchase our meta bags on the secondary market on our official Opensea.",
  "faq.8.question": "Is this mint the only chance to get a PINKO Meta Bag or participate in the PINKO Metaverse?",
  "faq.8.answer":
    "No. There will be multiple minting events. The first 15 Bags are going to be available in November 2022. The next drop have yet to be revealed.",
  "faq.9.question": "Is there a PINKO discord?",
  "faq.9.answer": "There isn’t, not yet! Follow us on Twitter, we’ll announce our Discord server soon! Come say hello.",
  "faq.10.question": "How about a PINKO twitter?",
  "faq.10.answer": "Glad you asked. Tweets from PINKO come from @PINKO.",
  "faq.otherQuestions1": "I got a question that is not answered here!",
  "faq.otherQuestions2": "We got you — send us an email at *[metaverse@pinko.com](mailto:metaverse@pinko.com)*",
  "nftPanel.buyCTA.label": "Buy",
  "nftDetails.price.label": "Price",
  "nftDetails.buyCTA.label": "Buy NFT",
  "nftDetails.registerCTA.label": "Discover",
  "nftDetails.connectCTA.label": "Connect metamask",
  "purchaseForm.buyCTA.label": "Next",
  "purchaseForm.privacy.label": `I have read and agree to PINKO Meta Love Bags <em><a target="_blank" href="https://www.pinko.com/customercare/privacy-policy.html">Privacy policy</a></em>`,
  "purchaseForm.marketing.label": `I confirm I have read the Privacy Notice in full pursuant to Article 13 of Regulation (EU) 679/2016 available at stores and on the website www.pinko.com. The personal data (“Data”) given in this form will be processed using paper and digital means, by Cris Conf SpA, with headquarters in Fidenza (Italy), Strada Comunale per Fornio, 132, in its capacity as controller, to analyse consumer choices and habits (“profiling”) and to send - by ordinary post, email or messaging systems - sales and/or promotional information and/or newsletters. Data may also be disclosed, collected and processed by third parties appointed as processors, including branches, subsidiaries and/or affiliated companies, franchisees and external entities that provide instrumental services for the purposes indicated in full in the notice, of which the complete list is available at the head office of the Controller. Not giving data will only prevent the Controller from informing you about customer initiatives. To exercise your rights as of Regulation (EU) 679/2016 (access to, correction, modification or erasure of data, etc.), you may write to the Controller indicated above or send an email to PinkoPrivacy@pinko.com . For additional information on data processing, and to consult the privacy notice again, please see the dedicated section on the website www.pinko.com (<em><a target="_blank" href="https://www.pinko.com/customercare/privacy-policy.html">By subscribing you accept our Privacy Policy</a></em>.)
  
  I consent to my personal data being processed for profiling and marketing purposes, including the sending of newsletters, electronic messages, notices on special projects with promotional purposes, phone contacts, as described in the privacy notice available in full on the website www.pinko.com`,
  "purchaseForm.name.label": "Name",
  "purchaseForm.surname.label": "Surname",
  "purchaseForm.email.label": "Email",
  "purchaseForm.phone.label": "Phone",
  "modal.leadGeneration.interest.title":
    "Fill the following form to access all the PINKO Meta Love Bags benefits, once you have purchased the NFT",
  "modal.leadGeneration.interest.subtitle":
    "By filling out this form, once you have purchased the NFT, you will have access to a number of exclusive benefits that include a VIC access to the new PINKO community, early access to future NFT drops, exclusive special offers for NFT owners, exclusive events organized by PINKO and a 10% discount valid for 12 months in all the PINKO boutiques.",
  "nftPanel.soldLabel": "Sold",
  "nftDetails.soldLabel": "Sold",
  "nftDetails.toast.buyError.label": "Error during the transaction",
  "nftDetails.purchaseOk.label": "Your purchase process complete correctly!",
  "nftDetails.goHome.button.label": "Go back home page",
  "nftDetails.toast.registrationError.label": "Error saving data",
  "myNFTs.countTitle.label": "N. of NFTs",
  "myNFTs.totalValueTitle.label": "Total value",
  "menu.mynfts.label": "MyNFTs",
  "myNFTs.title.label": "Wallet address",
  "myNFTs.name.label": "Name",
  "myNFTs.description.label": "Description",
  "myNFTs.price.label": "Price",
  "myNFTs.owner.label": "Owner",
  "myNFTs.seller.label": "Seller",
  "myNFTs.tokenId.label": "Token ID",
  "myNFTs.hash.label": "Hash",
  "purchaseForm.skipCTA.label": "Skip",
  "purchaseForm.privacy.title.label": "Privacy",
  "purchaseForm.marketing.title.label": "Marketing",
  "termsForm.terms.title.label": "Terms and conditions",
  "termsForm.terms.label":
    'I have read and agree to PINKO Meta Love Bags <em><a target="_blank" href="/terms.pdf">Terms and Conditions</a></em>',
  "termsForm.buyCTA.label": "Confirm",
  "modal.leadGeneration.terms.title": "Accept the terms and conditions to purchase the PINKO Meta Love Bags",
  "modal.leadGeneration.terms.subtitle": "",
  "title.benefits.text": "Benefits",
  "benefits.description": "Buying Meta Love Bags gives you access to several privileges online and offline:",
  "benefits.1.title": "VIC access to PINKO community",
  "benefits.1.description":
    "Exclusive VIC access (Very Important Customer) to the new PINKO Community, that will be launched in 2023;",
  "benefits.2.title": "PINKO community co-creation",
  "benefits.2.description":
    "All Meta Love Bag owners can participate through gated polls sessions, to the co-design of PINKO Community prizes and rewards;",
  "benefits.3.title": "Early access to future NFTs drops",
  "benefits.3.description": "Early Access for all the Meta Love Bags owners to the next PINKO NFT collection releases;",
  "benefits.4.title": "Special offers",
  "benefits.4.description": "Exclusive access to private sales and special offers;",
  "benefits.5.title": "Exclusive events",
  "benefits.5.description": "Exclusive Access to Special VIC Events;",
  "benefits.6.title": "10% discount",
  "benefits.6.description": "10% discount, valid for 12 months after the purchase.",
};

export { messages };
