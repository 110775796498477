import Link from "next/link";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { GridContainer } from "../../../layouts/grid_container";
import { AnimatePresence, motion } from "framer-motion";
import { Button } from "../../atoms/button";
import Logo from "./logo.svg";
import { MenuButton } from "./menu_button";
import { FooterLinks } from "../footer_links";

export const Menu = ({ onMetamaskClick, metamaskStatus }: { onMetamaskClick?: () => void; metamaskStatus: string }) => {
  const [isOnTop, setIsOnTop] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  useEffect(() => {
    const onTop = () => {
      window.scrollY > 10 ? setIsOnTop(false) : setIsOnTop(true);
    };
    window.addEventListener("scroll", onTop);

    return () => {
      window.removeEventListener("scroll", onTop);
    };
  }, []);
  return (
    <>
      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            initial={{ y: "-100%" }}
            animate={{ y: "0%" }}
            exit={{ y: "-100%" }}
            transition={{ stiffness: 100 }}
            className="fixed desktop:hidden bg-primary w-screen h-screen top-0 z-10 flex flex-col items-center justify-evenly pt-20 pb-4 text-secondary"
          >
            <div className="flex-grow flex flex-col items-center justify-evenly">
              <h4 onClick={() => setIsMobileMenuOpen(false)} className="text-2xl">
                <Link href="/#project">
                  <a>
                    <FormattedMessage id="menu.project.label" />
                  </a>
                </Link>
              </h4>
              <h4 onClick={() => setIsMobileMenuOpen(false)} className="text-2xl">
                <Link href="/#collection">
                  <a>
                    <FormattedMessage id="menu.collection.label" />
                  </a>
                </Link>
              </h4>
              <h4 onClick={() => setIsMobileMenuOpen(false)} className="text-2xl">
                <Link href="#benefits">
                  <a>
                    <FormattedMessage id="menu.benefits.label" />
                  </a>
                </Link>
              </h4>
              <h4 onClick={() => setIsMobileMenuOpen(false)} className="text-2xl">
                <Link href="/#roadmap">
                  <a>
                    <FormattedMessage id="menu.roadmap.label" />
                  </a>
                </Link>
              </h4>
              <h4 onClick={() => setIsMobileMenuOpen(false)} className="text-2xl">
                <Link href="/#faq">
                  <a>
                    <FormattedMessage id="menu.faq.label" />
                  </a>
                </Link>
              </h4>
              {metamaskStatus === "CONNECTED" && (
                <h4 onClick={() => setIsMobileMenuOpen(false)} className="text-2xl">
                  <Link href="/my-nfts">
                    <a>
                      <FormattedMessage id="menu.mynfts.label" />
                    </a>
                  </Link>
                </h4>
              )}
              <div>
                <Button label="Metamask" icon type="NEUTRAL_LIGHT" onClick={onMetamaskClick} />
              </div>
            </div>
            <div className="footer w-full">
              <FooterLinks />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <motion.div
        initial={{ y: "-100%" }}
        animate={{ y: 0 }}
        transition={{ type: "spring", stiffness: 200, damping: 40 }}
        className={`duration-500 transition-colors overflow-hidden ${
          isOnTop || isMobileMenuOpen ? "bg-transparent" : "bg-secondary"
        } fixed z-20 w-full`}
      >
        <GridContainer className="h-20 desktop:h-28 items-center text-primary">
          <div
            className={`w-full desktop:min-w-[150px] col-start-1 col-span-2 desktop:col-span-1  ${
              isMobileMenuOpen ? "text-accent" : "text-primary"
            }`}
          >
            <Link href="/">
              <a>
                <Logo />
              </a>
            </Link>
          </div>
          <div className="hidden desktop:flex desktop:flex-row desktop:items-center desktop:justify-evenly desktop:col-start-2 desktop:col-span-9">
            <Link href="/#project">
              <a>
                <h6 className="font-bold">
                  <FormattedMessage id="menu.project.label" />
                </h6>
              </a>
            </Link>
            <Link href="/#collection">
              <a>
                <h6 className="font-bold">
                  <FormattedMessage id="menu.collection.label" />
                </h6>
              </a>
            </Link>
            <Link href="#benefits">
              <a>
                <h6 className="font-bold">
                  <FormattedMessage id="menu.benefits.label" />
                </h6>
              </a>
            </Link>
            <Link href="/#roadmap">
              <a>
                <h6 className="font-bold">
                  <FormattedMessage id="menu.roadmap.label" />
                </h6>
              </a>
            </Link>
            <Link href="/#faq">
              <a>
                <h6 className="font-bold">
                  <FormattedMessage id="menu.faq.label" />
                </h6>
              </a>
            </Link>
            {metamaskStatus === "CONNECTED" && (
              <Link href="/my-nfts">
                <a>
                  <h6 className="font-bold">
                    <FormattedMessage id="menu.mynfts.label" />
                  </h6>
                </a>
              </Link>
            )}
          </div>
          <div className="hidden desktop:flex desktop:col-start-11 desktop:col-span-2 flex-col items-end">
            <Button label="Metamask" icon type="NEUTRAL" onClick={onMetamaskClick} />
          </div>
          <div
            onClick={() => setIsMobileMenuOpen((isOpen) => !isOpen)}
            className="flex desktop:hidden flex-row items-center justify-center text-primary col-start-6 tablet:col-start-12 px-[1px]"
          >
            <MenuButton isOpen={isMobileMenuOpen} />
          </div>
        </GridContainer>
      </motion.div>
    </>
  );
};
