import Link from "next/link";

interface Props {
  icon?: boolean;
  full?: boolean;
  label: React.ReactNode;
  type?: "ACCENT" | "NEUTRAL" | "NEUTRAL_LIGHT";
  onClick?: () => void;
  disabled?: boolean;
  htmlType?: "button" | "submit";
}

const Icon = () => {
  return (
    <svg viewBox="0 0 47 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.415 38.4903L19.5585 40.1203V37.9888L20.06 37.4873H23.5706V39.9948V41.7501H19.8093L15.1703 39.7441L13.415 38.4903Z"
        fill="#CDBDB2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.4751 38.4903L27.4571 40.1203V37.9888L26.9556 37.4873H23.4451V39.9948V41.7501H27.2063L31.8451 39.7441L33.4751 38.4903Z"
        fill="#CDBDB2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0601 33.8515L19.5586 37.9888L20.1854 37.4873H26.7049L27.4571 37.9888L26.9556 33.8515L25.9526 33.2245L20.9376 33.35L20.0601 33.8515Z"
        fill="#393939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6748 5.8927L19.6838 12.9137L21.0631 33.35H25.9526L27.4571 12.9137L30.2154 5.8927H16.6748Z"
        fill="#F89C35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.51051 21.3139L0 31.4692L8.77628 30.9677H14.4181V26.5796L14.1673 17.5526L12.9136 18.5556L3.51051 21.3139Z"
        fill="#F89D35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1553 22.4423L20.4361 22.693L19.3078 27.9588L14.418 26.705L10.1553 22.4423Z"
        fill="#D87C30"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1553 22.5676L14.418 26.5796V30.5917L10.1553 22.5676Z"
        fill="#EA8D3A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4181 26.705L19.4331 27.9587L21.0631 33.35L19.9346 33.9767L14.4181 30.717V26.705Z"
        fill="#F89D35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.418 30.7169L13.415 38.4902L20.06 33.8514L14.418 30.7169Z"
        fill="#EB8F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4361 22.693L21.0631 33.35L19.1823 27.8961L20.4361 22.693Z"
        fill="#EA8E3A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.65082 30.8424L14.4181 30.7169L13.4151 38.4902L8.65082 30.8424Z"
        fill="#D87C30"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75826 41.8754L13.4151 38.4902L8.65079 30.8424L0 31.4692L2.75826 41.8754Z"
        fill="#EB8F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6838 12.9137L14.2928 17.4272L10.1553 22.4422L20.4361 22.8185L19.6838 12.9137Z"
        fill="#E8821E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.415 38.4902L20.06 33.8514L19.5585 37.8635V40.1202L15.045 39.2425L13.415 38.4902Z"
        fill="#DFCEC3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.4751 38.4902L26.9556 33.8514L27.4571 37.8635V40.1202L31.9706 39.2425L33.4751 38.4902Z"
        fill="#DFCEC3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6778 24.9497L19.0571 27.8335L14.1673 26.5797L17.6778 24.9497Z"
        fill="#393939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.63275 0.00012207L19.6838 12.9137L16.8003 5.89266L2.63275 0.00012207Z"
        fill="#E88F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.6328 0L0.376038 6.89565L1.62979 14.4182L0.752164 14.9197L2.00592 16.0481L1.00291 16.9257L2.38204 18.1794L1.50442 18.9317L3.51042 21.4392L12.9136 18.5556C17.5107 14.8779 19.7674 12.9973 19.6839 12.9137C19.6003 12.8301 13.9166 8.52553 2.6328 0Z"
        fill="#8E5A30"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.3794 21.3139L46.89 31.4692L38.1137 30.9677H32.4719V26.5796L32.7226 17.5526L33.9764 18.5556L43.3794 21.3139Z"
        fill="#F89D35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.7347 22.4423L26.4539 22.693L27.5822 27.9588L32.4719 26.705L36.7347 22.4423Z"
        fill="#D87C30"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.7347 22.5676L32.4719 26.5796V30.5917L36.7347 22.5676Z"
        fill="#EA8D3A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.4719 26.705L27.4568 27.9587L25.8269 33.35L26.9553 33.9767L32.4719 30.717V26.705Z"
        fill="#F89D35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.4719 30.7169L33.4749 38.4902L26.9554 33.9767L32.4719 30.7169Z"
        fill="#EB8F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.4539 22.693L25.8269 33.35L27.7076 27.8961L26.4539 22.693Z"
        fill="#EA8E3A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.2391 30.8424L32.4719 30.7169L33.4749 38.4902L38.2391 30.8424Z"
        fill="#D87C30"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.1317 41.8754L33.4749 38.4902L38.2392 30.8424L46.89 31.4692L44.1317 41.8754Z"
        fill="#EB8F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.2061 12.9137L32.5972 17.4272L36.7347 22.4422L26.4539 22.8185L27.2061 12.9137Z"
        fill="#E8821E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.2121 24.9497L27.8329 27.8335L32.7226 26.5797L29.2121 24.9497Z"
        fill="#393939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.2572 0.00012207L27.2061 12.9137L30.0897 5.89266L44.2572 0.00012207Z"
        fill="#E88F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.2572 0L46.5139 6.89565L45.2602 14.4182L46.1378 14.9197L44.884 16.0481L45.887 16.9257L44.5079 18.1794L45.3855 18.9317L43.3795 21.4392L33.9764 18.5556C29.3793 14.8779 27.1225 12.9973 27.2061 12.9137C27.2897 12.8301 32.9734 8.52553 44.2572 0Z"
        fill="#8E5A30"
      />
    </svg>
  );
};

export const Button = ({ icon, label, type = "ACCENT", onClick, full, disabled, htmlType = "button" }: Props) => {
  let bgColor = "";
  let textColor = "";
  switch (type) {
    case "ACCENT":
      bgColor = "bg-accent";
      textColor = "text-secondary";
      break;
    case "NEUTRAL":
      bgColor = "bg-primary";
      textColor = "text-secondary";
      break;
    case "NEUTRAL_LIGHT":
      bgColor = "bg-secondary";
      textColor = "text-primary";
      break;
    default:
      bgColor = "bg-accent";
      textColor = "text-secondary";
  }

  return (
    <button
      disabled={disabled}
      type={htmlType}
      onClick={onClick}
      className={`transition duration-500 ${
        disabled ? "cursor-default" : "cursor-pointer"
      } py-2 px-8 rounded-full text-secondary flex flex-row flex-nowrap gap-5 items-center ${bgColor}
      ${full ? "w-full" : ""}
      ${disabled ? "opacity-20" : ""}`}
    >
      {icon && (
        <div className="w-8">
          <Icon />
        </div>
      )}
      <h6 className={`w-full ${textColor} font-bold`}>{label}</h6>
    </button>
  );
};
