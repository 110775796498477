import { motion, MotionConfig } from "framer-motion";

interface Props {
  isOpen?: boolean;
}

// const menuOpenVariants = {
//   topLine: { rotate: "45deg", y: 5 },
//   bottomLine: { rotate: "-45deg", y: -5 },
// };

const menuOpenVariants = {
  topLine: {
    y: [0, 5, 5],
    rotate: [0, 0, 45],
  },
  bottomLine: {
    y: [0, -5, -5],
    rotate: [0, 0, -45],
  },
};

const menuCloseVariants = {
  topLine: {
    y: [5, 5, 0],
    rotate: [45, 0, 0],
  },
  bottomLine: {
    y: [-5, -5, 0],
    rotate: [-45, 0, 0],
  },
};

export const MenuButton = ({ isOpen }: Props) => {
  return (
    <div className="max-w-[3.5rem] w-full flex flex-col items-center justify-center cursor-pointer">
      <MotionConfig transition={{ delay: 0.4 }}>
        <motion.div
          initial={false}
          animate={isOpen ? menuOpenVariants.topLine : menuCloseVariants.topLine}
          className={`w-full h-0.5 duration-500 transition-colors ${isOpen ? "bg-secondary" : "bg-primary"} mb-1`}
        ></motion.div>
        <motion.div
          initial={false}
          animate={isOpen ? menuOpenVariants.bottomLine : menuCloseVariants.bottomLine}
          className={`w-full h-0.5 duration-500 transition-colors ${isOpen ? "bg-secondary" : "bg-primary"} mt-1`}
        ></motion.div>
      </MotionConfig>
    </div>
  );
};
