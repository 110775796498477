import clsx from "clsx";
import { CSSProperties } from "react";

interface Props {
  className?: string;
  style?: CSSProperties | undefined;
  children: React.ReactNode;
}

export const GridContainer = ({ className, children, style }: Props) => (
  <div style={style} className={clsx("layout", className)}>
    {children}
  </div>
);
