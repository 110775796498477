import "../common/assets/styles/globals.scss";
import type { AppProps } from "next/app";
import { messages as messagesIT } from "../common/assets/locales/it";
import { messages as messagesEN } from "../common/assets/locales/en";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";
import { LOCALES } from "../common/assets/locales";
import { IntlProvider } from "react-intl";
import { LayoutOverlay } from "../common/layouts/grid_container";
import { NFTContextWrapper, useMetaMask } from "@mirrorprod/nft-utils";
import { Menu } from "../common/components/molecules/menu";
import { useVh } from "@mirrorprod/mexc";
import { ToastContainer, Zoom, Flip } from "react-toastify";
import { AnimatePresence, motion } from "framer-motion";
import "react-toastify/dist/ReactToastify.min.css";

const MenuWrapper = () => {
  const { onUserInteraction, status } = useMetaMask(
    process.env.NEXT_PUBLIC_NFT_CONTRACT_ID || "",
    process.env.NEXT_PUBLIC_CHAIN_TYPE
  );
  return <Menu metamaskStatus={status} onMetamaskClick={async () => await onUserInteraction()} />;
};

function MyApp({ Component, pageProps }: AppProps) {
  const { locale, asPath, events } = useRouter();
  const router = useRouter();
  const messages = useMemo(() => {
    switch (locale) {
      case LOCALES.IT:
        return messagesIT;
      case LOCALES.EN:
        return messagesEN;
      default:
        return messagesEN;
    }
  }, [locale]);

  useVh();

  useEffect(() => {
    events?.on("routeChangeComplete", (url: string) => {
      if (url.includes("#")) {
        console.log(url);
        const hashPieces = url.split("#");
        if (hashPieces[1]) {
          setTimeout(() => {
            window.location.hash = "";
            window.location.hash = hashPieces[1];
          }, 300);
        }
      }
    });
  }, [events, router]);

  return (
    <IntlProvider locale={locale || LOCALES.EN} messages={messages}>
      {process.env.NEXT_PUBLIC_SHOW_LAYOUT && <LayoutOverlay />}
      <NFTContextWrapper>
        <MenuWrapper />
        <AnimatePresence exitBeforeEnter>
          <motion.div
            key={asPath.replace(/#.*/, "")}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="overflow-x-hidden"
          >
            <Component {...pageProps} />
          </motion.div>
        </AnimatePresence>
      </NFTContextWrapper>
      <ToastContainer transition={Flip} />
    </IntlProvider>
  );
}

export default MyApp;
